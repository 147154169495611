:root {
  --themecolora: #4056a1;
  --themecolorb: #f8f8f8;
  --themecolorc: #c8e1f5;
  --backgrounda: #5e80ac;
  --backgroundb: #f8f8f8;
  --bordertext: #333333;
  --texta: #f8f8f8;
  --textb: #000000;

  --margin: 18vh;
  --marginhalf: 9vh;
  --marginborder: 5vh;

  --fontsizexl: 18vh;
  --fontsizetitlea: 11vh;
  --fontsizetitleb: 7vh;
  --fontsizetitlec: 5vh;
  --fontsizecontenta: 3vh;
  --fontsizecontentb:  2vh;
  --fontsizecontentc:  1.7vh;
  --fontsizefooter:  1vh;
  --fontsizeborder:  1.8vh;
}

body {
  margin: 0;
  font-family: system-ui,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
